import React from 'react';
import { Box, Group, Text, Flex, Image } from '@chakra-ui/react';


const Header: React.FC = () => (
  <Box as="header" color="white" bg="#18181b" padding="10px">
    <Flex>
      <Box marginRight="10px" alignSelf="center">
        <Image src="https://mc.movielabs.com/static/78b75b22fd317edd14d9a6dba4b47290/logo_website_gradient_dark.svg" alt="MovieLabs Logo" style={{ height: '25px' }} />
      </Box>
      <Group marginEnd="auto">
        <Text fontWeight="bold">OMC Validator</Text>
      </Group>
    </Flex>
  </Box>
);

export default Header; 